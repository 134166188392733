import {CampaignDayData, CampaignTaskData} from "../../lib/transform-json";
import React from "react";
import {useLanguage} from "../../contexts/LanguageContext";
import {ActionTooltip} from "../../components/shared/tooltip";
import {ChartArea, ListStart, LucideMonitor, MoreVertical} from "lucide-react";
import {Separator} from "../../components/shared/separator";

interface DayItemProps {
    dayData: CampaignDayData;
    onClickDetail?: (id: string) => void;
    onClickRestart?: (id: string) => void;
}

const DayItem: React.FC<DayItemProps> = ({dayData, onClickDetail, onClickRestart}) => {

    const {translate} = useLanguage();

    return (
        <div>
            <div
                className={`flex flex-col w-full rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border`}>
                <div
                    className={`flex flex-row w-full ${dayData.status === 'PENDING' ? 'bg-gray/10' : (dayData.status === 'IN_PROGRESS' ? "bg-orange/10" : "bg-green/10")} justify-between items-center p-2`}>

                    <div className={"flex flex-row gap-2 items-center"}>
                        <span
                            className={"text-gray-dark dark:text-white"}>{translate('campaigns.detail.day')} {dayData.day}
                        </span>
                    </div>

                    <div className={"flex flex-row gap-2"}>
                        {onClickRestart && (
                            <ActionTooltip
                                label={translate(dayData.status === 'PENDING' ? 'campaigns.detail.start-from-day' : 'campaigns.detail.restart-from-day')}>
                                <div
                                    className={"cursor-pointer flex p-1 hover:bg-green/30 transition-all rounded-md"}
                                    onClick={() => onClickRestart && dayData.id && onClickRestart(dayData.id)}>
                                    <ListStart className={"text-gray-dark dark:text-white"} size={15}/>
                                </div>
                            </ActionTooltip>
                        )}
                        <ActionTooltip label={translate('campaigns.detail.detail-progress-day')}>
                            <div
                                className={"cursor-pointer flex p-1 hover:bg-green/30 transition-all rounded-md"}
                                onClick={() => onClickDetail && dayData.id && onClickDetail(dayData.id)}>
                                <ChartArea className={"text-gray-dark dark:text-white"} size={15}/>
                            </div>
                        </ActionTooltip>
                    </div>
                </div>
                <div className={"flex flex-col gap-2 p-2"}>
                    <div className={"flex flex-row items-start gap-2"}>
                        <span className={"text-xs text-gray-dark dark:text-white"}>{translate('campaigns.detail.day-status')}:</span>
                        <span
                            className={`${dayData.status === 'PENDING' ? 'text-gray' : (dayData.status === 'IN_PROGRESS' ? "text-orange" : "text-green")} text-xs`}>{dayData.status}</span>
                    </div>
                    <div className={"flex flex-col items-start gap-1"}>
                        <span className={"text-xs text-gray-dark dark:text-white"}>{translate('campaigns.detail.tasks-detail')}</span>
                        <Separator orientation={"horizontal"}/>
                        <div className={"w-full grid grid-cols-2 gap-1"}>
                            {dayData.tasks.map((task, index) => (
                                <ItemTask key={"day_" + dayData.day + "_task_" + index} taskData={task}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

type ItemTaskProps = {
    taskData: CampaignTaskData;
}

const ItemTask: React.FC<ItemTaskProps> = ({taskData}) => {
    return (
        <div className={"flex flex-col w-full rounded-md overflow-hidden border border-gray-border dark:border-gray-dark-border p-2 gap-1"}>
            <div className={"flex flex-row gap-2"}>
                <span className={"text-xs text-gray-dark dark:text-white"}>Type:</span>
                <span className={"text-xs text-blue"}>{taskData.type}</span>
            </div>
            <div className={"flex flex-row gap-2"}>
                <span className={"text-xs text-gray-dark dark:text-white"}>Count:</span>
                <span className={"text-xs text-gray-dark dark:text-white"}>{taskData.count}</span>
            </div>
            {Object.entries(taskData.other_params).map(([key, value]) => (
                <div key={"param_" + taskData.id} className={"flex flex-row gap-2"}>
                    <span className={"text-xs text-gray-dark dark:text-white"}>{key}:</span>
                    <span className={"text-xs text-gray-dark dark:text-white"}>{value}</span>
                </div>
            ))}
        </div>
    );
}

const ItemDayCardLoader = () => {
    return (
        <div role="status"
             className={"flex flex-col w-full rounded-md border border-gray-border dark:border-gray-dark-border"}>
            <div className={"flex flex-row bg-gray/10 justify-between items-center p-2"}>
                <div className={"h-3 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
            </div>
            <div className={"flex flex-row gap-2 p-2"}>
                <div className={"flex flex-col items-start gap-1"}>
                    <div className={"h-2 w-28 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-28 bg-gray/50 mt-2 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                    <div className={"h-2 w-36 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}></div>
                </div>
            </div>
        </div>
    )
}

export {DayItem, ItemDayCardLoader}
