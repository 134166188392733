import React from "react";
import {Plus} from "lucide-react";
import {useLanguage} from "../../contexts/LanguageContext";
import {ActionTooltip} from "../../components/shared/tooltip";
import {Button} from "../../components/shared/button";

type TasksTabsProps = {
    children: React.ReactNode;
    days: number[];
    onAddDay: () => void;
    onRemove: () => void;
    onDayClick: (day: number) => void;
    selectedDay: number;
}

export const TasksTabs: React.FC<TasksTabsProps> = ({
                                                        children,
                                                        days = [1],
                                                        onAddDay,
                                                        onRemove,
                                                        onDayClick,
                                                        selectedDay
                                                    }) => {

    const {translate} = useLanguage();

    return (
        <div className={"flex flex-col bg-gray/10 lg:w-[50%] md:w-fit sm:w-fit border-2 rounded-md border-green/30"}>
            <div className={"grid grid-cols-10 items-center justify-center gap-1 p-1 "}>
                {days.map((day, index) => (
                    <div key={index}
                         onClick={() => onDayClick(day)}
                         className={`${selectedDay === day ? 'bg-green/10' : ''} flex p-2 min-w-[60px] items-center justify-center hover:bg-green/30 text-xs text-gray-dark dark:text-white border rounded-md cursor-pointer border-gray-border dark:border-gray-dark-border`}>
                        <span>{translate('campaigns.add.day')} {day}</span>
                    </div>
                ))}
                {days.length < 14 && (
                    <ActionTooltip label={translate('campaigns.add.add-day')}>
                        <div
                            className={"flex text-xs min-w-[60px] p-2 text-gray-dark dark:text-white hover:bg-green/30 items-center justify-center border rounded-md cursor-pointer border-gray-border dark:border-gray-dark-border"}
                            onClick={onAddDay}
                        >
                            <Plus size={16}/>

                        </div>
                    </ActionTooltip>
                )}
            </div>
            <div className={"flex flex-col border-t border-gray-border dark:border-gray-dark-border gap-4 p-2"}>
                {children}

                {selectedDay === days.length && selectedDay > 1 && (
                    <div className={"flex flex-row justify-end"}>
                        <Button onClick={onRemove}
                                className={"bg-orange/10 hover:bg-orange/30"}>{translate('campaigns.add.remove-day')}</Button>
                    </div>
                )}
            </div>
        </div>
    )
}
