import React, {useEffect, useRef, useState} from 'react';
import {useClickAway} from 'react-use';
import {DateRange} from 'react-date-range';
import {addDays} from "date-fns";
import {enUS, ru, uk} from 'date-fns/locale';
import {CalendarRange} from "lucide-react";
import {useLanguage} from "../../contexts/LanguageContext";

type DateRangePickerPopoverProps = {
    initDates?: { startDate: Date, endDate: Date };
    onApply?: (startDate: Date, endDate: Date) => void;
}

const DateRangePickerPopover: React.FC<DateRangePickerPopoverProps> = ({initDates, onApply}) => {

    const {language, translate} = useLanguage();
    const [locale, setLocale] = useState(enUS);

    const [isOpen, setIsOpen] = useState(false);
    const [selectionRange, setSelectionRange] = useState({
        startDate: initDates?.startDate || addDays(new Date(), -7),
        endDate: initDates?.endDate || new Date(),
        key: 'selection'
    });

    const handleSelect = (ranges: any) => {
        setSelectionRange(ranges.selection);
    };

    useEffect(() => {
        switch (language) {
            case 'en':
                setLocale(enUS);
                break;
            case 'ua':
                setLocale(uk);
                break;
            case 'ru':
                setLocale(ru);
                break;
            default:
                setLocale(enUS);
        }
    }, [language]);

    const applyDates = () => {
        const startDate = new Date(selectionRange.startDate);
        startDate.setHours(0, 0, 0, 0);

        const endDate = new Date(selectionRange.endDate);
        endDate.setHours(23, 59, 59, 999);

        onApply?.(startDate, endDate);
        setIsOpen(false);
    }

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const dateToStingLocale = (date: Date) => {
        const dateOptions: Intl.DateTimeFormatOptions = {day: 'numeric', month: 'short', year: 'numeric'};

        return date.toLocaleDateString(language === 'ua' ? 'uk' : language, dateOptions);
    }

    const ref = useRef(null);

    useClickAway(ref, () => {
        setIsOpen(false);
    });

    return (
        <div className="relative inline-block">
            <div onClick={toggleOpen}
                 className="flex text-xs flex-row p-2 border border-gray/30 rounded-md cursor-pointer w-fit text-gray-dark dark:text-white items-center justify-center hover:bg-green/20 transition-all">
                <CalendarRange size={16}/>
                <span className="ml-2">{dateToStingLocale(selectionRange.startDate)} --- {dateToStingLocale(selectionRange.endDate)}</span>
            </div>
            {isOpen && (
                <div
                    ref={ref}
                    className="absolute top-12 left-0 z-50 bg-white/20 dark:bg-white/20 shadow-lg rounded p-4 backdrop-blur">
                    <div className={"flex flex-col gap-3"}>
                        <DateRange
                            ranges={[selectionRange]}
                            locale={locale}
                            maxDate={new Date()}
                            minDate={addDays(new Date(), -365)}
                            onChange={handleSelect}
                            moveRangeOnFirstSelection={false}
                            rangeColors={['#039e46']}
                        />
                        <div className={"flex flex-row gap-1 justify-end"}>
                            <span
                                onClick={applyDates}
                                className="p-2 bg-green/70 hover:bg-green transition-all text-white rounded-md cursor-pointer text-center">
                            {translate('def.apply')}
                        </span>
                            <span onClick={toggleOpen}
                                  className="p-2 bg-red/70 hover:bg-red transition-all text-white rounded-md cursor-pointer text-center">
                            {translate('def.cancel')}
                        </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateRangePickerPopover;
