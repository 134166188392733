import {type ClassValue, clsx} from "clsx"
import {twMerge} from "tailwind-merge"
import ReactDOM from "react-dom";
import {ReactNode} from "react";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

interface PortalProps {
    children: ReactNode;
}

export function Portal({children}: PortalProps) {
    const el = document.getElementById('portal-root');
    if (!el) return null;
    return ReactDOM.createPortal(children, el);
}

export function getDateLocal(value: string | null | undefined, locale: string | undefined) {
    if (value) {
        const dateObj = new Date(value);

        const dateOptions: Intl.DateTimeFormatOptions = {day: 'numeric', month: 'short'};
        const timeOptions: Intl.DateTimeFormatOptions = {hour: '2-digit', minute: '2-digit'};

        const localDateStr = dateObj.toLocaleDateString(locale === 'ua' ? 'uk' : locale, dateOptions);
        const localTimeStr = dateObj.toLocaleTimeString(locale === 'ua' ? 'uk' : locale, timeOptions);

        return `${localDateStr} ${localTimeStr}`;
    }
    return "";
}

export function getDateFromString(value: string | null | undefined) {
    if (value) {
        try {
            return new Date(value);
        } catch (ignored) {
            return null;
        }
    }
    return null;
}
