import {useLanguage} from "../../contexts/LanguageContext";
import {Card} from "../../components/shared/card";
import {InstallMonitor} from "./install-monitor";
import {useEffect, useState} from "react";
import {CampaignData} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {Separator} from "../../components/shared/separator";
import {ApexDonutChart, ChartDonutData} from "../../components/shared/apex-chart";
import {ProgressLine} from "../../components/shared/progress-line";
import {cn} from "../../lib/utils";

export default function DashboardScene() {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();

    const [campaignSelect, setCampaignSelect] = useState<CampaignData | null>(null);
    const [response, setResponse] = useState<any | null>(null);

    const loadStatistics = () => {
        axiosPrivate.get(`/statistic/campaign/keys?cuid=${campaignSelect?.id}`).then((response) => {
            if (response.status === 200 && response.data.data) {
                setResponse(response.data.data);
            }
        }).catch((error) => {
            console.error('Error fetching statistics:', error);
        });
    }

    useEffect(() => {
        if (campaignSelect) {
            loadStatistics();
            setResponse(null);
        }
    }, [campaignSelect]);

    return (
        <div className="flex flex-col gap-4">
            <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('dashboard.title-page')}</span>
            <div className="flex flex-row">
                <InstallMonitor onChangeCampaign={(campaign) => setCampaignSelect(campaign)}/>
            </div>
            <div className="flex flex-row">
                <Card title={"Statistics by days"}>
                    {response && Object.keys(response).map((key) => {
                            return (
                                <>
                                    <div key={key} className={cn("flex flex-row justify-between items-center")}>
                                        <span
                                            className={"text-lg font-bold text-gray-dark dark:text-white"}>
                                            {key.split("$$").length > 1 ? key.replace("$$", " - ") : key.replace("$$", "")}
                                        </span>
                                        <div className={"grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 items-center"}>
                                            {Object.entries(response[key]).map(([k, v]) => {
                                                const data = v as ChartDonutData; // Приведение типа

                                                const day = campaignSelect?.days.find((day) => day.day.toString() === k);

                                                let classNameStatus = "";

                                                if (day) {
                                                    day.status === "FINISHED" ? classNameStatus = "bg-green/10" : day.status === "IN_PROGRESS" ? classNameStatus = "bg-orange/10" : classNameStatus = "";
                                                }

                                                const success = data.series[0];
                                                const total = data.total!!;

                                                return data && data.labels && data.series ? (
                                                    <div key={k}
                                                         className={cn("flex flex-col gap-1 p-1 border border-gray rounded-md m-1 items-center", classNameStatus)}>
                                                        <span className={"text-gray-dark dark:text-white"}>Day {k}</span>
                                                        <ApexDonutChart data={{
                                                            labels: data.labels,
                                                            series: data.series
                                                        }}/>
                                                        <span className={"text-gray-dark dark:text-white"}>Count {success}/{total}</span>
                                                        <span className={"text-gray-dark dark:text-white"}>Progress {(success / total * 100).toFixed(2)}%</span>
                                                        <ProgressLine progress={success / total * 100}/>
                                                    </div>
                                                ) : (
                                                    <div key={k} className={"flex flex-col gap-1 p-1 border border-gray rounded-md m-1 items-center"}>
                                                        <span className={"text-red-500"}>Data not available</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <Separator orientation={"horizontal"} className={"m-1"}/>
                                </>
                            )
                        }
                    )}
                </Card>
            </div>
        </div>
    );
}
