import React, {useEffect, useState} from "react";
import {Card} from "../../components/shared/card";
import {Table, TableBody, TableCell, TableColumnHeaderCell, TableHeader, TablePagination, TableRow} from "../../components/shared/table";
import {ActionTooltip} from "../../components/shared/tooltip";
import {SearchIcon, X} from "lucide-react";
import {useLanguage} from "../../contexts/LanguageContext";
import {SmsData, SmsDataList, transformJsonToSmsDataListCount} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {getDateFromString, getDateLocal} from "../../lib/utils";
import {useToast} from "../../contexts/ToastContext";
import {SmsTimer} from "./SmsTimer";
import {Input} from "../../components/shared/Input";

export const SmsListScene: React.FC = () => {

    const {translate} = useLanguage();
    const axiosPrivate = useAxiosPrivate();

    const {language} = useLanguage();
    const {add} = useToast();

    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(localStorage.getItem(`itemsPerPage_table`) || '10'));
    const [search, setSearch] = useState<string>('');
    const [sorted, setSorted] = useState<{ key: string, ask: boolean }>({key: 'timeCreate', ask: false});

    const [smsData, setSmsData] = useState<SmsDataList>({
        totalCount: 0,
        list: []
    });

    const loadSms = () => {
        axiosPrivate.get(`/sms-services/sms/list?search=${search}&page=${currentPage}&size=${itemsPerPage}&sort=${sorted.key + ':' + sorted.ask}`)
            .then((response) => {
                if (response.status === 200) {
                    setSmsData(transformJsonToSmsDataListCount(response.data.data));
                }
                setLoading(false);
            }).catch((error) => {
            console.log(error);
            setLoading(false);
        });
    }

    const handleCancel = (item: SmsData) => {

        add({type: 'info', message: 'Sms is canceled coming soon'});

        return;
    }

    const handleSetCancelStatus = (item: SmsData) => {
        setSmsData(prevState => {
            prevState.list = prevState.list.map((sms) => {
                if (sms.id === item.id && sms.status !== 'SUCCESS') {
                    sms.status = 'CANCEL';
                }
                return sms;
            });
            return {...prevState};
        })
    }

    useEffect(() => {
        loadSms();
    }, [axiosPrivate, currentPage, itemsPerPage, search, sorted]);

    return (
        <div className="flex flex-col gap-4">
            <div className={"flex flex-row justify-between mb-2"}>
                <span className="text-2xl font-bold text-gray-dark dark:text-white">{translate('sms-service.sms.title-page')}</span>
                <div className={"flex w-52"}>
                    <Input
                        type={"text"}
                        label={translate('def.search')}
                        onChange={(e) => setSearch(e.target.value)}
                        icon={<SearchIcon size={20}/>}
                    />
                </div>
            </div>

            <Card>
                <div className={"flex flex-col gap-4"}>
                    <Table loading={loading} onSorted={(key, ask) => setSorted({key: key, ask: ask})}>
                        <TableHeader>
                            <TableColumnHeaderCell>
                                Id
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell>
                                {translate('sms-service.sms.phone')}
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell>
                                {translate('sms-service.sms.codes')}
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell sortKey={"status"}>
                                {translate('def.status')}
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell sortKey={"timeCreate"}>
                                {translate('sms-service.sms.time-create')}
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell sortKey={"timeUpdate"}>
                                {translate('def.time-update')}
                            </TableColumnHeaderCell>
                            <TableColumnHeaderCell>
                                {translate('def.actions')}
                            </TableColumnHeaderCell>
                        </TableHeader>

                        <TableBody>
                            {smsData.list.map((item, index) => (
                                <TableRow key={index} rowIndex={index}>
                                    <TableCell>
                                        {item.id}
                                    </TableCell>
                                    <TableCell>
                                        {item.phone}
                                    </TableCell>
                                    <TableCell>
                                        {item.codes === null || item.codes.length === 0 ? translate('sms-service.sms.not-found-codes') : item.codes.map((code, index) => (
                                            <div key={index} className={"flex flex-row gap-1 justify-center"}>
                                                <span
                                                    className={"text-xs font-bold text-gray-dark dark:text-white"}>
                                                    {code.code}{code.machine_id == null ? '' : ' - '}

                                                    {code.machine_id == null ? '' :
                                                        <a className={"hover:text-green transition-all"} href={`/machines?search=${code.machine_id}`}
                                                           target={"_blank"} rel={"noreferrer"}>{code.machine_id}</a>
                                                    }
                                                </span>
                                            </div>
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        {item.status} {getDateFromString(item.time_create) == null ? '' :
                                        (getDateFromString(item.time_create)!!.getTime() + 1020000 > new Date().getTime() ?
                                            <SmsTimer endTime={new Date(item.time_create).getTime() + 1020000} endCallback={() => handleSetCancelStatus(item)}/>
                                            : '')}
                                    </TableCell>
                                    <TableCell>
                                        {getDateLocal(item.time_create, language)}
                                    </TableCell>
                                    <TableCell>
                                        {getDateLocal(item.time_update, language)}
                                    </TableCell>
                                    <TableCell>
                                        <div className={"flex flex-row justify-center gap-2 p-2"}>
                                            {getDateFromString(item.time_create)!!.getTime() + 1020000 < new Date().getTime() ? '' :
                                                <ActionTooltip label={translate('def.cancel')}>
                                                    <div
                                                        className={"p-1 text-gray-dark dark:text-white rounded-md bg-red/30 hover:bg-red/50 cursor-pointer transition-all"}
                                                        onClick={() => handleCancel(item)}
                                                    >
                                                        <X size={14}/>
                                                    </div>
                                                </ActionTooltip>
                                            }
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                    <TablePagination totalItems={smsData.totalCount}
                                     currentPage={currentPage}
                                     itemsPerPage={itemsPerPage}
                                     onChangeItemsPerPage={setItemsPerPage} onChangePage={setCurrentPage}/>
                </div>
            </Card>
        </div>
    )
}
