import React, {useEffect, useState} from "react";
import {ActionTooltip} from "../../components/shared/tooltip";
import {RefreshCwIcon} from "lucide-react";
import {Separator} from "../../components/shared/separator";
import {ProgressLine} from "../../components/shared/progress-line";
import {Card} from "../../components/shared/card";

const PassiveData: React.FC = () => {

    const loadingDataInit = {
        init: true,
        refresh: false,
    }

    const [loading, setLoading] = useState(loadingDataInit);

    const handleRefresh = () => {
        setLoading({
            ...loading,
            refresh: true
        });

        //TODO request refresh data if error show toast

        setTimeout(() => {
            setLoading({
                ...loading,
                refresh: false
            });
        }, 2500);
    }

    //Test
    useEffect(() => {

        //TODO request data from server

        setTimeout(() => {
            setLoading({
                ...loading,
                init: false
            });
        }, 1500);
    }, []);

    return (
        <Card>
            <div className={"flex flex-row w-full items-center justify-between"}>
                <span className={"font-bold text-white"}>Passive Data</span>

                <div className={"flex flex-row gap-1 items-center"}>
                    {loading.init || loading.refresh ?
                        <div className={"w-20 h-2 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                        <ActionTooltip label={"Last update time"}>
                            <span className={"text-xs text-gray/50"}>27.01 11:12</span>
                        </ActionTooltip>
                    }
                    <ActionTooltip label={"Refresh"}>
                        <div
                            className={"p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white relative flex items-center transition-all"}
                            onClick={handleRefresh}
                        >
                            <RefreshCwIcon
                                size={20}
                                className={`text-gray-dark dark:text-white cursor-pointer ${loading.init || loading.refresh ? 'animate-[spin_1.5s_infinite]' : ''}`}/>
                        </div>
                    </ActionTooltip>
                </div>
            </div>

            <Separator orientation={"horizontal"}/>

            <div className={"flex flex-col gap-2"}>
                <div className={"grid sm:grid-cols-2 lg:grid-cols-4"}>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-orange/80 text-xs font-bold"}>Work accounts</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-orange/80 text-xs font-bold"}>Verify</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-orange/80 text-xs font-bold"}>Ban</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>
                        }
                    </div>
                </div>
                <div className={"px-2"}>
                    <Separator orientation={"horizontal"}/>
                </div>
                <div className={"grid sm:grid-cols-2 lg:grid-cols-4"}>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-orange/80 text-xs font-bold"}>B-Loop</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-orange/80 text-xs font-bold"}>Wrong Sync</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-orange/80 text-xs font-bold"}>Wrong Machine</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>
                        }
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-orange/80 text-xs font-bold"}>Wrong Build</span>
                        {loading.init ?
                            <div className={"w-12 h-6 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                            <span className={"font-bold text-gray-dark dark:text-white"}>0</span>
                        }
                    </div>
                </div>
                <div className={"px-2"}>
                    <Separator orientation={"horizontal"}/>
                </div>
                <div className={"flex flex-col gap-1"}>
                    <div className={"flex flex-row"}>
                        <ActionTooltip label={"Count free account"}>
                            <span className={"text-xs text-gray/60"}>Free Accounts</span>
                        </ActionTooltip>
                    </div>
                    <div className={"flex flex-row justify-between gap-2"}>
                        <div className={"flex justify-start items-center w-12"}>
                            {loading.init ?
                                <div className={"w-12 h-3.5 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                                <span className={"text-gray-dark dark:text-white text-xs"}>100.00%</span>
                            }
                        </div>

                        <div className={"flex w-full items-center"}>
                            <ProgressLine progress={19.00} loading={loading.init}/>
                        </div>
                        <div className={"flex justify-center items-center w-12"}>
                            {loading.init ?
                                <div className={"w-12 h-3.5 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                                <span className={"text-gray-dark dark:text-white text-xs"}>10000</span>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </Card>
    )
}
export {PassiveData}
